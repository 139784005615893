.Intro {
    width: 100vw;
    height: 100vh;
    background: red;
    position: relative;
}


.Intro__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
}

.Intro__greetings {
    color: white;
    text-transform: uppercase;
    text-align: center;
    
}

.Intro__banar{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background: white;
    padding: 10px 30px;
    border-radius: 50px;
    min-width: 430px;
}

.Intro__logo {
    width: 60px;
    height: 30px;
}

.Intro__desc {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.Intro__text {
    font-size: 14px;
}

.Intro__header {
    font-size: 25px;
    color: purple;
    width: fit-content;
}

.Intro__logo img {
    width: 100%;
    height: 100%;
}

.Intro__background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.Intro__background--img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Intro__backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
}