.About {
    width: 100%;
    height: calc(100vh - 60px);
    background: red;
    display: flex;
    flex-direction: row;
}

.About__left {
    width: 60%;
    height: 100%;
    background: yellow;
    position: relative;
}

.About__lef--content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.About__left--badge {
    position: relative;
    min-height: 200px;
}

.About__badge--circle-big {
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.About__badge--circle-small {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.7);
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.About__badge--icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.About__badge--icon i {
    font-size: 30px;
}

.About__left--desc {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.About__left--desc-text {
    color: white;
    text-align: center;
}

.About__left--header {
    text-align: center;
    color: white;
    font-size: 25px;
}

.About__backdrop {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(170, 0, 240, 0.9), rgba(245, 50, 255, 0.5));
    position: absolute;
    top: 0;
    left: 0;
}


.About__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.About__right {
    width: 40%;
    height: 100%;
    background: white;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.About__right--purple-big {
    position: absolute;
    left: 30%;
    top: 100px;
    width: 100px;
    height: 100px;
    background: #ffb5ff;
    border-radius: 20px;
}

.About__right--purple-small {
    position: absolute;
    right: 30px;
    top: 200px;
    background: #ffb5ff;
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.About__right--gray {
    position: absolute;
    right: 10%;
    top: 50px;
    width: 150px;
    height: 150px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.About__right--content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 5;
}

.About__right--header {
    display: flex;
    flex-direction: column;
}

.About__right--header-big {
    font-size: 30px;
    font-weight: bold;
}

.About__right--header-small {
    font-size: 15px;
    color: gray;

}

.About__right--desc {
    font-size: 14px;
    color: gray;
}

.About__right--features {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.About__right--feature {
    display: flex;
    flex-direction: column;
    /*gap: 5px;*/
    position: relative;
}

.About__right--feature-bar {
    width: 100%;
    position: relative;
}

.About__right--feature-container {
    width: 100%;
    height: 10px;
    background: lightblue;
    border-radius: 5px;
}

.About__right--feature-color {
    height: 10px;
    background: #c600c6;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
}

.About__right--feature-rate {
    position: absolute;
    right: 5px;
    top: 0;
}