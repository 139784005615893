.Idea {
    height: calc(100vh - 60px);
    width: 100%;
    position: relative;
}

.Idea img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Idea__details {
    height: 50%;
    width: 100%;
    background: #c600c6;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
}

.Idea__details--left {
    width: 50%;
    height: 100%;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
}

.Idea__details--features {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.Idea__details--icon {
    font-size: 30px;
    color: gray;
}

.Idea__details--desc {
    background: #ffb9ff;
    border-radius: 20px;
    padding: 20px;
}

.Idea__details--desc-header {
    color: mediumpurple;
    font-size: 20px;
    font-weight: bold;
}

.Idea__details--desc-text {
    font-size: 14px;
}

.Idea__details--right {
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, #ff83ff, purple);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 20px;
    color: white;
    position: relative;
}

.Idea__details--right-header {
    font-size: 30px;
}

.Idea__details--right-text {
    font-size: 18px;
}

.Idea__details--right-square-smale {
    width: 100px;
    height: 100px;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    right: 150px;
    top: 150px;
    z-index: 3;
    border-radius: 20px;
}

.Idea__details--right-square-big {
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    border-radius: 20px;
}