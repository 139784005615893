.Closing {
    height: calc(100vh - 60px);
    width: 100%;
    position: relative;
    overflow: hidden;
}

.Closing__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.Closing__backdrop--left {
    width: 30%;
    height: 100%;
    background: #ffb9ff;
    opacity: 0.5;
}

.Closing__backdrop--right {
    width: 70%;
    height: 100%;
    background: white;
}

.Closing__sideElem {
    position: absolute;
    right: -430px;
    top: 70%;
    transform: translateY(-50%) rotate(45deg);
    width: 500px;
    height: 500px;
    border-radius: 50px;
    background: linear-gradient(to right, purple, pink, rebeccapurple);

}

.Closing__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
    gap: 20px;
}

.Closing__logo {
    width: 70px;
    height: 50px;

}

.Closing__logo img {
    width: 100%;
    height: 100%;
}

.Closing__header {
    text-align: center;
    font-size: 30px;
    font-family: Arial,
    Helvetica, sans-serif;
    background: linear-gradient(
            to right, purple, pink,
            purple, rebeccapurple);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.Closing__header p {
    font-size: 16px;
}