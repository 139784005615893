.Partners {
    width: 100%;
    height: calc(100vh - 60px);
    position: relative;
}

.Partners__sideElem {
    position: absolute;
    width: 500px;
    height: 500px;
    background: linear-gradient(45deg, purple, pink, mediumpurple);
    left: -430px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border-radius: 40px;

}

.Partners__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    z-index: 4;
    position: relative;
}

.Partners__header {
    font-size: 30px;
    font-weight: bold;
}

.Partners__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 40px;
    width: 80%;
}

.Partners__item {
    width: 22%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.Partners__item--img {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    outline: 10px purple solid;
}

