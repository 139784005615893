.Ceo {
    height: calc(100vh - 60px);
    width: 100%;
    position: relative;
}

.Ceo__backdrop {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: row;

    /*background: red;*/
}

.Ceo__backdrop--left {
    width: 65%;
    color: white;
    height: 100%;
    position: relative;
}

.Ceo__backdrop--left-purple1 {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: #ffa6d5;
    position: absolute;
    top: 50px;
    left: 50px;
    opacity: 0.5;
}

.Ceo__backdrop--left-purple2 {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    background: #ffcbff;
    position: absolute;
    top: 100px;
    right: 20%;
    opacity: 0.5;
}

.Ceo__backdrop--left-gray {
    height: 90px;
    width: 90px;
    background: lightgrey;
    top: 30%;
    position: absolute;
    right: 30%;
    border-radius: 20px;
    opacity: 0.5;
}

.Ceo__backdrop--right {
    width: 35%;
    height: 100%;
    background: linear-gradient(45deg, purple, rebeccapurple);
}

.Ceo__container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.Ceo__content {
    width: 60%;
    padding: 10%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.Ceo__header h2 {
    font-size: 30px;
}

.Ceo__header p {
    font-size: 14px;
    color: gray;
}

.Ceo__name {
    font-size: 24px;
    color: gray;
}

.Ceo__desc {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Ceo__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}