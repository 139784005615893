.Sections {
    height: 50vh;
    background: black;
    position: relative;
}

.Sections__upper {
    width: 100%;
    height: 40%;
    background: linear-gradient(to right, purple, pink);
}

.Sections__bottom {
    background: white;
    height: 60%;
}

.Sections__container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.Sections__section {
    width: 30%;
    height: 200px;
    max-width: 300px;
    border-radius: 20px;
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.Sections__section--header h5 {
    font-size: 25px;
    color: gray;
    font-style: italic;
}

.Sections__section--icon {
    font-size: 35px;
}

.Sections__section--desc {
    font-size: 14px;
    text-align: center;
    color: gray;
}