.Navbar {
    height: 60px;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    background: white;
    padding: 0 10px;
}

.Navbar__links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    padding: 0;
}

.Navbar__socials {
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style: none;;
}

.Navbar__links--link {
    text-decoration: none;
    color: gray;
}

.Navbar__socials--link {
    text-decoration: none;
    color: gray;
}

