.Register {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Register__left {
    width: 60%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.Register__desc {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.Register__desc--quote-left {
    position: absolute;
    top: 0;
    color: #c600c6;
}

.Register__desc--quote-right {
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #c600c6;
}

.Register__shape--purple {
    width: 80px;
    height: 80px;
    background: #ff83ff;
    border-radius: 20px;
    position: absolute;
    bottom: 160px;
    right: 50px;
}

.Register__shape--purple-big {
    width: 100px;
    height: 100px;
    background: #ff83ff;
    position: absolute;
    bottom: 20px;
    right: 0;
    border-radius: 20px;
}

.Register__desc--text {
    font-size: 15px;
    text-align: center;
}

.Register__shape--gray {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 150px;
    height: 150px;
    background: lightgray;
    border-radius: 20px;
}

.Register__header {
    text-align: center;
    font-size: 30px;
}

.Register__right {
    width: 40%;
}

.Register__right img {
    width: 100%;
    height: 100%;
}