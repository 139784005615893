.Portfolio {
    height: calc(100vh - 60px);
    width: 100%;
    position: relative;
    overflow: hidden;
}

.Portfolio__backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
}

.Portfolio__backdrop--left {
    width: 25%;
    height: 100%;
    background: #ffaeff;
}

.Portfolio__backdrop--right {
    width: 75%;
    height: 100%;
    background: white;
    position: relative;
}

.Portfolio__backdrop--right-small {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #ff83ff;
    opacity: 0.7;
    position: absolute;
    left: 15%;
    top: 100px;
}

.Portfolio__backdrop--right-big {
    width: 100px;
    height: 100px;
    background: #ff83ff;
    opacity: 0.7;
    border-radius: 20px;
    top: 20px;
    left: 20%;
    position: absolute;
}

.Portfolio__backdrop--right-gray {
    width: 100px;
    height: 100px;
    opacity: 0.7;
    border-radius: 20px;
    position: absolute;
    bottom: 50px;
    right: 40% ;
    background: lightgrey;
}

.Portfolio__backdrop--right-sideElem {
    width: 500px;
    height: 500px;
    background: linear-gradient(90deg, purple, mediumpurple, pink);
    position: absolute;
    top: 50%;
    right: -430px;
    border-radius: 30px;
    transform: translateY(-50%) rotate(135deg);
}

.Portfolio__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.Portfolio__header {
    position: absolute;
    top: 30px;
    right: 100px;
    direction: rtl;
}

.Portfolio__header p {
    font-size: 15px;
    color: gray;
}

.Portfolio__projects {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.Portfolio__projects--item1 {
    width: 200px;
    height: 200px;
    border-radius: 30px;
    position: absolute;
    left: 13%;
    top: 10%;
}

.Portfolio__projects--item1 img {
    border-radius: 40px;
}

.Portfolio__projects--item2 {
    width: 80px;
    height: 80px;
    border-radius: 30px;
    position: absolute;
    bottom: 20%;
    left: 15%;
}

.Portfolio__projects--item2 img {
    border-radius: 30px;
}

.Portfolio__projects--item3 {
    width: 150px;
    height: 150px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    left: 45%;

}

.Portfolio__projects--item3 img {
    border-radius: 50px;
}

.Portfolio__projects--item4 {
    width: 150px;
    height: 150px;
    border-radius: 50px;
    position: absolute;
    bottom: 18%;
    left: 45%;
}

.Portfolio__projects--item4 img {
    border-radius: 50px;
}

.Portfolio__projects--item5 {
    width: 100px;
    height: 100px;
    border-radius: 40px;
    position: absolute;
    top: 27%;
    right: 25%;
}

.Portfolio__projects--item5 img {
    border-radius: 40px;
}