.Main {
    height: 40vh;
    width: 100%;;
}

.Main__upper {
    height: 100%;
    width: 100%;
    position: relative;
}

.Main__upper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Main__backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}