* {
  border: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
/*  height: 10000px;*/
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}