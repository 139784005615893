.Vision {
    width: 100%;
    height: calc(100vh - 60px);
    /*background: red;*/
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.Vision__left {
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, purple, pink);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 30px;
}

.Vision__left--icon {
    position: relative;
    width: 150px;
    height: 150px;

}

.Vision__left--icon-big {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
}

.Vision__left--icon-small {
    width: 80%;
    height: 80%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.Vision__left--icon-i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 50px;
}

.Vision__left--header {
    text-align: center;
    font-size: 20px;
    color: white;
}

.Vision__left--text {
    color: white;
    font-size: 14px;
    text-align: center;
}

.Vision__right {
    width: 50%;
    height: 100%;
}

.Vision__right--top {
    height: 50%;
    width: 100%;
}

.Vision__right--top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Vision__right--bottom {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.Vision__right--bottom-header {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.Vision__right--bottom-text {
    font-size: 14px;
    text-align: center;
}